.dataLoadingAnimation {
    rect {
        fill: #b2b2b2;
    }

    @keyframes :global(a0_t) {
        0% {
            transform: translate(19px, 41px);
        }

        60% {
            transform: translate(19px, 76px);
        }

        100% {
            transform: translate(19px, 41px);
        }
    }

    @keyframes :global(a0_h) {
        0% {
            height: 64px;
        }

        60% {
            height: 29px;
        }

        100% {
            height: 64px;
        }
    }

    @keyframes :global(a1_t) {
        0% {
            transform: translate(86px, 28px);
        }

        40% {
            transform: translate(86px, 82px);
        }

        100% {
            transform: translate(86px, 28px);
        }
    }

    @keyframes :global(a1_h) {
        0% {
            height: 77px;
        }

        40% {
            height: 23px;
        }

        100% {
            height: 77px;
        }
    }

    @keyframes :global(a2_t) {
        0% {
            transform: translate(52.5px, 64px);
        }

        60% {
            transform: translate(52.5px, 42px);
        }

        100% {
            transform: translate(52.5px, 64px);
        }
    }

    @keyframes :global(a2_h) {
        0% {
            height: 41px;
        }

        60% {
            height: 63px;
        }

        100% {
            height: 41px;
        }
    }
}
