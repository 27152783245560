.underConstructionState {
    align-items: center;
    background-color: #F6F6F6;
    display: flex;
    height: calc(100vh - 200px);
    justify-content: center;
    margin: 0px 16px;
}

.notIdealState {
    margin: 0px;
}
