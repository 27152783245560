.tvWidgetLoad {
  height: 100%;

  :global(.loading-state-content) {
    margin-top: 0px;
  }

  :global(.loading-state) {
    background: transparent;
    height: 100%;
    align-items: center;
  }
}