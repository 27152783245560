.italic {
    font-style: italic;
}

.disclaimer {
    margin: 8px 0;
}

.scheduleDetailAgGridContainer {
    height: 95%;
}