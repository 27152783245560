.containerLoadingState {
    margin: 0px auto;
    margin-top: 312px;
    width: 400px;
    height: 64px;
    padding: 16px;
    border-radius: 2px;

    &:global(.black) {
        opacity: 0.8;
        background: #002032;

        :global(.copy) {
            color: #ffffff;

            h2 {
                color: #ffffff;
            }
        }
    }

    &:after {
        clear: both;
        content: "";
        display: table-cell;
    }

    :global(.animation) {
        float: left;
        width: 64px;

        img {
            width: 64px;
        }
    }

    :global(.copy) {
        float: left;
        margin-left: 16px;
        width: 320px;

        h2 {
            color: #000000;
            font-size: 24px;
            font-weight: normal;
            line-height: 24px;
            margin: 0px;
            padding: 0px;
            text-align: left;
        }

        p {
            line-height: 16px;
            margin: 0;
            padding: 0;
            text-align: left;
        }
    }
}
