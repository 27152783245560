.tableStateWrapper {
    background-color: #F6F6F6;
    display: flex;
    height: calc(100vh - 200px);
    padding: 16px 16px 16px 16px;
    width: 100%;

    > div {
        margin: 0px auto;
        margin-top: 312px;

        &:after {
            clear: both;
            content: "";
            display: table-cell;
        }

        :global(.animation) {
            float: left;
            width: 64px;

            img {
                width: 64px;
            }
        }

        :global(.copy) {
            float: left;
            margin-left: 16px;
            width: 320px;

            h2 {
                color: #000000;
                font-size: 24px;
                font-weight: normal;
                line-height: 24px;
                margin: 0px;
                padding: 0px;
                text-align: left;
            }

            p {
                line-height: 16px;
                margin: 0;
                padding: 0;
                text-align: left;
            }
        }
    }
}

.action {
    cursor: pointer;
    background-color: #F6F6F6;
    margin-top: 16px;
}
